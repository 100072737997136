/* #### Generated By: http://www.cufonfonts.com #### */
@font-face {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"), url("/fonts/Segoe UI.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI";
  font-style: italic;
  font-weight: normal;
  src: local("Segoe UI Italic"),
    url("/fonts/Segoe UI Italic.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  src: local("Segoe UI Semibold"),
    url("/fonts/Segoe UI Semibold.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: bold;
  src: local("Segoe UI Bold"), url("/fonts/Segoe UI Bold.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI";
  font-style: italic;
  font-weight: bold;
  src: local("Segoe UI Bold Italic"),
    url("/fonts/Segoe UI Bold Italic.woff") format("woff");
}
