@import "bootstrap/dist/css/bootstrap.min.css";
@import "react-datepicker/dist/react-datepicker.css";
@import "react-toastify/dist/ReactToastify.min.css";
@import "leaflet/dist/leaflet.css";
@import "nprogress/nprogress.css";
@import "font";

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  list-style: none;
  color: #5076ff;
}

//
.spinner {
  animation: spin infinite 2s linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table {
  color: #5076ff;
}
.table-hover tbody tr:hover {
  color: #0062cc;
  background-color: rgba(0, 0, 0, 0.075);
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #f5f4f1;
  color: #5076ff;
  box-shadow: 0px 3px 6px #00000029;
}

.leaflet-popup-content {
  margin: 5px -15px !important;
}
.leaflet-container a.leaflet-popup-close-button {
  color: red;
}

.flex-1 {
  flex: 1;
}

//hidden
:root {
  --darkgreen: #343a40; /*#18202E;*/
  --sideNavWidth: calc(220px + 5rem);
}

.ImageParent {
  display: block;
  width: 60px;
  height: 70px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.breadcrumb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.5rem 1rem !important;
  margin-bottom: -2px !important;
  list-style: none;
  //background-color: #F5F5F6 !important;
  border-radius: 0.25rem;
}

//change btn bootstrap
.btn-group-sm > .btn,
.btn-sm {
  padding: 3px 10px;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
}

//
::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: /*darkgrey;*/ #cccccc;
  // outline: 1px solid slategrey;
  border-radius: 8px;

  &:hover {
    background-color: darkgrey;
  }
}
//

*,
::before,
::after {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none !important;

  &:focus {
    outline: 0;
  }
}

button:focus {
  outline: 0;
}

main {
  background-color: #fff;
  flex: 1;
}

.main-with-p {
  padding: 5rem 4rem;
}

//Message d'erreur accès backend
.erreur {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

//border backend
.border-left-primary {
  border-left: 0.25rem solid #4e73df !important;
}
.border-left-secondary {
  border-left: 0.25rem solid #5a6268 !important;
}
.border-left-success {
  border-left: 0.25rem solid #1cc88a !important;
}
.border-left-danger {
  border-left: 0.25rem solid #c82333 !important;
}
.border-left-info {
  border-left: 0.25rem solid #36b9cc !important;
}
.text-xs {
  font-size: 0.7rem;
}
.search {
  margin: -8px;
}
//

.form-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    label {
      color: #5076ff;
      font-size: 0.9rem;
    }
  }
}

form {
  text-align: left;

  .input-group {
    margin-bottom: 10px;
    label {
      display: block;
      margin-bottom: 5px;
      color: #394761;
      font-size: 14px;
    }

    input,
    select,
    textarea {
      display: block;
      height: 36px;
      width: 100%;
      padding: 5px 8px;
      line-height: 1;
      background-color: #b1c8eb;
      outline: 0;
      border-radius: 4px;
      border: 1px solid #a8c4e5;
      color: #394761;
    }
  }
}

.breadcrumb-item.active {
  color: #5076ff;
}

textarea {
  color: #5076ff !important;
}
.form-control {
  background-color: #f7f9fc !important;
}

input:not([type="file"]),
select {
  background-color: #f7f9fc !important;
  outline: 0;
  border-radius: 4px;
  color: #5076ff !important;

  &:not(.is-valid),
  &:not(.is-invalid) {
    border: 1px solid #a8c4e5;
  }
}

::placeholder {
  color: #a8c4e5 !important;
}

//Responsive table
@media all and(max-device-width:  992px) {
  .table-resp {
    td {
      white-space: nowrap;
      font-size: 12px;
    }
    th {
      font-size: 12px;
      font-weight: bold;
    }
  }
}

.plain-btn {
  padding: 4px 10px;
  background-color: #fbc216;
  border: 1px solid #fbc216;
  color: #fff !important;
  border-radius: 3px;
  transition: 0.4s;
  user-select: none;
  text-align: center;
  display: block;
  min-width: 350px;

  @media all and (max-device-width: 480px) {
    min-width: 250px;
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: #fbc216;
      color: #fff;
      box-shadow: 0 1px 10px 1px rgba(#222, 0.25);
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.full-width {
    width: 100%;
  }
}

.login-issue {
  margin: 10px 0;
  text-align: center;
  font-size: 0.9rem;

  a {
    color: blue;
    opacity: 0.7;
    transition: 0.4s;

    &:hover {
      text-decoration: underline;
      opacity: 1;
    }
  }
}

.or {
  text-align: center;
  margin: 10px 0;

  &::after,
  &::before {
    content: "";
    display: inline-block;
    background-color: #cdcd;
    height: 2px;
    width: 40%;
    position: relative;
    top: -4px;
  }
}

.goto-register-btn {
  padding: 4px 10px;
  background-color: transparent;
  border: 1px solid #fbc216;
  color: #fbc216;
  border-radius: 3px;
  transition: 0.4s;
  user-select: none;
  text-align: center;
  display: block;
  min-width: 350px;

  @media all and (max-device-width: 480px) {
    min-width: 250px;
  }

  &:hover,
  &:focus {
    box-shadow: 0 1px 10px 1px rgba(#222, 0.25);
  }

  &.full-width {
    width: 100%;
  }
}

.animate-child {
  cursor: pointer;
  .to-animate {
    animation-duration: 2s;
    transform-origin: bottom;
  }

  &:hover {
    .to-animate {
      animation-name: bounce;
      animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    }
  }
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-10px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-5px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.form-group {
  position: relative;
}

.invalid-tooltip {
  left: auto;
  right: 0;
  top: 0;
}
.badge-warning {
  padding: 5px 5px;
  //background-color: #fbc216 !important;
  //border: 2px solid #fbc216;
  color: #fff;
  border-radius: 2px;
  transition: 0.4s;
  user-select: none;

  &:hover {
    background-color: #fbc216;
    color: #fff;
    box-shadow: 0 1px 10px 1px rgba(#222, 0.25);
  }
}

.badge-success {
  padding: 5px 5px;
  //background-color: #fbc216 !important;
  //border: 2px solid #fbc216;
  color: #fff;
  border-radius: 2px;
  transition: 0.4s;
  user-select: none;

  &:hover {
    //background-color: #fbc216;
    color: #fff;
    box-shadow: 0 1px 10px 1px rgba(#222, 0.25);
  }
}

.btnHover {
  padding: 3px 10px;
  border: 1px solid #fbc216;
  color: #fbc216;
  border-radius: 3px;
  transition: 0.4s;
  background-color: #fff;
  //user-select: none;
  cursor: pointer;

  &:hover {
    background-color: #fbc216;
    color: #fff;
    box-shadow: 0 1px 10px 1px rgba(#222, 0.25);
  }
}

.headingBack {
  color: #1647fb;
  @media all and (max-device-width: 480px) {
    font-size: 1.6em;
  }
  @media screen and (min-width: 700px) {
    font: normal normal 600 40px/76px Segoe UI;
  }
}

.btnPlein {
  padding: 3px 10px;
  background-color: #fbc216;
  border: 2px solid #fbc216;
  color: #fff;
  border-radius: 3px;
  transition: 0.4s;
  @media all and (max-device-width: 480px) {
    font-size: 14px;
  }

  &:hover {
    background-color: #fbc216;
    color: #fff;
    box-shadow: 0 1px 10px 1px rgba(#222, 0.25);
  }

  &:disabled {
    cursor: not-allowed;
  }
}

input {
  &:disabled {
    cursor: not-allowed;
    background-color: #e4e6e7 !important;
  }
}

//header
.stext-109 {
  font-size: 14px;
}
.cl8 {
  color: #999;
}

.no-resize {
  resize: none;
}

.hidden-input-file {
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: transparent;
  outline: 0 !important;
  border-radius: inherit;
  border: 1px solid #a8c4e5;

  &::-webkit-file-upload-button {
    display: none;
    width: 0;
    height: 0;
    font-size: 0;
    background-color: transparent !important;
    color: transparent;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(skyblue, 0.5);
  }
}

.hidden-input-file-create {
  padding: 4px 6px;
  width: 45%;
  @media screen and (min-width: 1040px) {
    height: 88%;
  }
  background: #a8c4e5;

  &::-webkit-file-upload-button {
    display: none;
    width: 0;
    height: 0;
    font-size: 0;
    background-color: transparent !important;
    color: transparent;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(skyblue, 0.5);
  }
}

.img-upload {
  width: 210px;
  height: 145px;
  background: #ccc url("/add-img-bg.gif") center no-repeat;
  background-size: cover;

  &.edit-mode {
    width: 100%;
    height: 152px;
  }

  &.user-pp-viewer {
    background-image: url("/img/user-pp-bg.png");
    width: 150px;
    height: 150px;
    border-radius: 50%;

    &.add-new {
      background-image: url("/img/user-pp-bg-add.png");
    }
  }

  &.bank-logo-viewer {
    width: 180px;
    height: 160px;
  }
}

//DatePicker
.react-datepicker {
  background-color: #f7f9fc;
  border: 1px solid #a8c4e5;
  border-radius: 3px;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  color: #5076ff;
}

//Date disable
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  text-decoration: line-through;
  color: #d90a1e5c !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #5076ff;
  color: #fff;
}

.react-datepicker__header {
  background-color: #f7f9fc !important;
  border-top-left-radius: 0rem;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0rem;
  border-bottom: 1px solid #a8c4e5;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name {
  text-transform: capitalize;
  color: #5076ff !important;
}

.react-datepicker__day-name {
  font-weight: 600;
}

.react-datepicker__day {
  user-select: none;
}

.react-datepicker__navigation {
  overflow: inherit;
}

.react-datepicker__navigation-icon {
  width: 100%;
}

.react-datepicker__navigation-icon--previous,
.react-datepicker__navigation-icon--next {
  &::before {
    top: 50%;
    border-color: #f6cf58;
  }

  &:hover::before {
    border-color: #5076ff;
    border-color: #fbc216;
  }
}

.react-datepicker__navigation-icon--previous::before {
  left: 10px;
  @media screen and (max-width: 992px) {
    transform: translateY(-50%) rotate(225deg);
  }
}

.react-datepicker__navigation-icon--next::before {
  right: 10px;
  left: auto;
  @media screen and (max-width: 992px) {
    transform: translateY(-50%) rotate(45deg);
  }
}

//Portal Modal
.react-datepicker__portal {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(12px);
}

//phone tablette
@media all and (max-device-width: 992px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 18px;
    line-height: 2rem;
  }

  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: 0.9rem;
  }

  .react-datepicker__day-name {
    font-size: 0.6rem;
  }
}

.fw-600 {
  font-weight: 600;
}
.fw-500 {
  font-weight: 500;
}

.quickviewModalTitle {
  @media screen and (max-width: 500px) {
    font-size: 1rem;
    margin-top: 15px !important;
    padding-left: 10px;
  }
}

.img-wrapper {
  .resp-img {
    max-width: 100%;
    max-height: 100%;
  }
}

@media all and (max-device-width: 480px) {
  .catalogue {
    font-size: 20px;
  }
  .heading-auth {
    font-size: 25px;
  }
}
